<template>
  <section id="dictionaries_general" class="dictionaries_general">
    <transition name="fade" mode="out-in" >
      <div class="section-top-row">
        <div class="row row-narrow align-items-center no-print position-relative">
          <div class="col order-md-0 order-0">
            <h1>Справочники</h1>
          </div>
<!--          <div class="col-md-auto col mt-md-0 mt-2 order-md-1 order-3 table-view-navigator">-->
<!--            <SearchTable/>-->
<!--          </div>-->
          <div class="col-auto order-md-2 mt-md-0 mt-2 order-4 table-view-navigator"
               v-if="['SuperAdminCities'].includes($route.name)">
            <DataTableFilterButton/>
          </div>
          <div class="col-auto order-md-4 order-1 table-view-navigator">
            <DataTableExcel/>
          </div>
          <div class="col-auto order-md-5 order-2 table-view-navigator"
               v-if="['superadmin', 'supermanager'].includes(role)">
            <AddObject module="superadmin"/>
          </div>
        </div>
      </div>
    </transition>
    <div class="sub-menu no-print">
      <router-link v-for="(m, mind) in menu"
                   :to="{ name: m.to }"
                   :key="`superadmin-link-${mind}}`">
        {{ $t(m.title) }}
      </router-link>
    </div>
    <router-view/>
  </section>
</template>

<script>
import AddObject from "@/components/parts/datatable/AddObject"
import DataTableExcel from "@/components/parts/datatable/DataTableExcel"
import DataTableFilterButton from "@/components/parts/datatable/DataTableFilterButton.vue"

export default {
  name: "SuperAdminDictionariesGeneral",
  components: {
    DataTableFilterButton,
    DataTableExcel,
    AddObject,
  },
  data() {
    return {
      menu: [
        { to: 'SuperAdminCountries', title: 'countries' },
        { to: 'SuperAdminCities', title: 'cities' },
        { to: 'SuperAdminSources', title: 'sources' },
      ]
    }
  },
  computed: {
    role() {
      return this.$store.state.auth.user.role
    },
  }
}
</script>
